




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CORRECT_ASSIGNED } from '../../../store/actions';
import { ConservatorshipDetails, CorrectAssigned } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker
  }
})
export default class CorrectAssignedDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CORRECT_ASSIGNED) correctAssigned!: (params: CorrectAssigned) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: CorrectAssigned = {
    conservatorship_id: this.conservatorship.id,
    assigned: this.conservatorship.assigned,
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        assigned: this.conservatorship.assigned
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.correctAssigned(this.value);
  }
}
